export const ChartOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 6
        },
        gridLines: {
          display: true
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          display: false
        }
      }
    ]
  },
  legend: {
    display: true,
    position: 'bottom'
  },
  tooltips: {
    enabled: true,
    mode: 'point'
  },
  responsive: true,
  maintainAspectRatio: false
}
export const PieChartOptions = {
  legend: {
    display: true,
    position: 'bottom'
  },
  tooltips: {
    enabled: true,
    mode: 'point',
    callbacks: {
      label: function (tooltipItem, data) {
        return `${data.labels[tooltipItem.index]} \n ${data.datasets[tooltipItem.datasetIndex].label}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}`
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false
}
export const ChatTypeOptions = [
  { title: 'Vertical Bar Chart', value: 'BarChart' },
  { title: 'Horizontal Bar Chart', value: 'HorizontalBarChart' },
  { title: 'Line Chart', value: 'LineChart' },
  { title: 'Pie Chart', value: 'PieChart' },
  { title: 'Doughnut Chart', value: 'DoughnutChart' }
]
export const ColorSchemeOptions = [
  {
    name: 'Classic', value: ['#619ED6', '#6BA547', '#F7D027', '#E48F1B', '#B77EA3', '#E64345', '#60CEED', '#9CF168', '#F7EA4A', '#FBC543', '#FFC9ED', '#E6696E']
  },
  { name: 'Midnight', value: ['#97A1D9', '#6978C9', '#4A5596', '#2C3365', '#111539'] },
  { name: 'Ocean', value: ['#77C2FE', '#249CFF', '#1578CF', '#0A579E', '#003870'] },
  { name: 'Moss', value: ['#62BEB6', '#0B9A8D', '#077368', '#034D44', '#002B24'] },
  { name: 'Berry', value: ['#F88FB2', '#ED5C8B', '#D5255E', '#A31246', '#740030'] },
  { name: 'Parachute', value: ['#E65F8E', '#A86BD1', '#3AA5D1', '#3BB58F', '#3A63AD'] },
  { name: 'Rainforest', value: ['#82C272', '#00A88F', '#0087AC', '#005FAA', '#323B81'] },
  { name: 'Sunset', value: ['#FFCA3E', '#FF6F50', '#D03454', '#9C2162', '#772F67'] },
  { name: 'Custom', value: ['', '', '', '', ''] }
]
