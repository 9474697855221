<template>
  <b-sidebar
    id="chart-settings"
    body-class="custom-scroll color-black"
    v-model="showChartSettingsSideBar"
    aria-labelledby="Chart settings"
    aria-label="Chart settings"
    shadow
    bg-variant="white"
    :backdrop-variant="`dark`"
    no-close-on-esc
    no-close-on-backdrop
    lazy
    backdrop
    right
    width="70vw"
    @shown="assignEditChart()"
    @hidden="sideBarHidden"
  >
    <template #header="{ hide }">
      <h3 class="font-21 theme-font-medium mb-0">Chart settings</h3>
      <div class="sidebar-header-close">
        <a
          href="javascript:void(0)"
          aria-labelledby="Chart settings title"
          @click="
            hide;
            $emit('closeSideBar');
          "
          ><img src="../../assets/img/close.png" alt="close-tab"
        /></a>
      </div>
    </template>
    <template #default="{}">
      <b-row>
        <b-col sm="5">
          <h4 class="page-sub-header">Configuration</h4>
          <b-form
            id="chart-configurations-from"
            name="chart-configurations-from"
            autocomplete="off"
            novalidate
          >
            <b-form-row>
              <b-col>
                <b-form-group>
                  <div class="floating-select-field">
                    <b-form-select
                      id="chart-type"
                      class="form-control"
                      :options="ChatTypeOptions"
                      value-field="value"
                      text-field="title"
                      v-model="formData.chartType"
                      @input="formData.chartType = $event"
                      required
                      :class="{
                        'is-value-exist': formData.chartType != null,
                        'is-invalid': !formData.chartType && formSubmitted,
                      }"
                    >
                    </b-form-select>
                    <label for="chart-type">Chart type</label>
                    <b-form-invalid-feedback
                      class="d-block"
                      v-if="!formData.chartType && formSubmitted"
                      >Chart type required.</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group>
                  <div class="floating-input-field">
                    <b-form-input
                      id="chart-title"
                      :class="{
                        'is-invalid':
                          (!formData.chartTitle && formSubmitted) ||
                          chartTitleExistError,
                      }"
                      type="text"
                      placeholder=" "
                      v-model="formData.chartTitle"
                      @input="chartTitleExistError = false"
                      required
                    ></b-form-input>
                    <label for="chart-title">Chart title</label>
                    <div class="add-info">
                      <small>This should match the key in API response *</small>
                    </div>
                    <b-form-invalid-feedback
                      class="d-block"
                      v-if="!formData.chartTitle && formSubmitted"
                      >Chart title required.</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback
                      class="d-block"
                      v-if="chartTitleExistError && formSubmitted"
                      >Chart title already exist.</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group>
                  <div class="floating-input-field">
                    <b-form-input
                      id="chart-subtitle"
                      type="text"
                      placeholder=" "
                      v-model="formData.subTitle"
                    ></b-form-input>
                    <label for="chart-subtitle"
                      >Chart subtitle (Optional)</label
                    >
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group>
                  <h4 class="page-sub-header pb-2">Select color scheme</h4>
                  <b-form-radio-group
                    v-model="formData.isExistingColor"
                    name="chat-color-scheme"
                    @change="onChangeColorScheme"
                    aria-describedby="Chart color scheme options"
                  >
                    <b-form-radio :value="true">Existing color </b-form-radio>
                    <b-form-radio :value="false"
                      >Custom color scheme
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="formData.isExistingColor">
              <b-col>
                <b-form-group>
                  <div class="floating-select-field">
                    <b-form-select
                      id="existing-color-scheme-options"
                      class="form-control"
                      v-model="formData.colorSchemeLst"
                      @input="formData.colorSchemeLst = $event"
                      @change="onSelectExistingColorScheme"
                      required
                      :class="{
                        'is-value-exist': formData.colorSchemeLst != null,
                        'is-invalid': !formData.colorSchemeLst && formSubmitted,
                      }"
                    >
                      <template v-for="(color, index) in ColorSchemeOptions">
                        <b-form-select-option
                          v-if="color.name != 'Custom'"
                          :key="index"
                          :value="color.value"
                          >{{ color.name }}</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                    <label for="existing-color-scheme-options"
                      >Chart color scheme</label
                    >
                    <b-form-invalid-feedback
                      class="d-block"
                      v-if="!formData.colorSchemeLst && formSubmitted"
                      >Chart color scheme required.</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="!formData.isExistingColor">
              <b-col sm="4" v-for="(hex, index) in customColors" :key="index">
                <b-form-group>
                  <div class="floating-input-field">
                    <b-form-input
                      :id="`custom-color-${index}`"
                      :class="{
                        'is-invalid': !isValidColor(index),
                      }"
                      type="text"
                      placeholder=" "
                      v-model="formData.colorSchemeLst[index]"
                      @focus="showColorPicker = index"
                      @blur="showColorPicker = -1"
                      @input="applyColorToPicker(index)"
                      :style="{
                        color: `${formData.colorSchemeLst[index]} !important`,
                      }"
                      required
                    ></b-form-input>
                    <label :for="`custom-color-${index}`"
                      >Color {{ index + 1 }}</label
                    >
                    <color-picker
                      v-if="showColorPicker == index"
                      theme="dark"
                      :color="formData.colorSchemeLst[index]"
                      :sucker-hide="true"
                      @input="changeColor($event, index)"
                      @changeColor="changeColor($event, index)"
                      class="color-picker"
                    />
                  </div>
                  <b-form-invalid-feedback :state="isValidColor(index)"
                    >Invalid color</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-form-invalid-feedback
                class="d-block pl-2 mt-n2"
                v-if="!customColorValidations && formSubmitted"
                >Atleast one color required.</b-form-invalid-feedback
              >
            </b-form-row>
          </b-form>
          <div class="mt-3" v-if="formData.chartType">
            <h4 class="page-sub-header mb-3">Sample api response</h4>
            <div
              class="sample-api-response custom-scroll"
              :style="{ height: `${sampleResHeight}px` }"
            >
              <pre class="pl-2 custom-scroll">
{
    "id": 1,
    "key": "{{ formData.chartTitle }}",
    "detail": "Some detail info",
    "value": "{{ getSampleAPIValue }}",
    "showChart": true,
    "description": "Some description info"
}
</pre
              >
            </div>
          </div>
        </b-col>
        <b-col sm="7">
          <h4 class="page-sub-header">Preview</h4>
          <div class="chart-settings-preview p-3" id="chart-settings-preview">
            <div class="chart-preview" id="chart-preview">
              <p v-if="!formData.chartType">
                Please select chart type to preview.
              </p>
              <div
                class="d-flex align-items-center justify-content-between mb-1"
              >
                <div
                  class="chart-preview-title w-100"
                  v-if="formData.chartTitle"
                >
                  {{ formData.chartTitle }}
                </div>
                <div
                  v-b-popover.html.hover="`Some description info`"
                  v-if="formData.chartTitle"
                  class="ml-1"
                >
                  <BIconInfoCircle class="color-gray" scale="1.2" />
                </div>
              </div>
              <div class="font-14 text-center" v-if="formData.subTitle">
                {{ formData.subTitle }}
              </div>
              <component
                :is="formData.chartType"
                :styles="chartHeight"
                :formData="formData"
              ></component>
            </div>
            <CustomizeChartData class="pt-4" v-if="formData.chartType" />
          </div>
        </b-col>
      </b-row>
    </template>
    <template #footer="{}">
      <b-button
        type="button"
        v-activeBlur
        class="btn-h-44"
        variant="outline-secondary"
        @click="onResetChartForm"
        >Reset</b-button
      >
      <b-button
        type="button"
        v-activeBlur
        @click="onAddUpdateChartForm"
        variant="primary"
        class="ml-3 btn-h-44"
        >{{ editChartData ? `Update` : `Add` }}
      </b-button>
    </template>
  </b-sidebar>
</template>
<script>
import { mapState } from 'vuex'
import colorPicker from '@caohenghu/vue-colorpicker'
import { ChatTypeOptions, ColorSchemeOptions } from '../charts/chartOptions'
import { cloneDeep } from 'lodash'
import { BIconInfoCircle } from 'bootstrap-vue'
import { useValidateFields } from '@/composables/useValidateFields'
export default {
  name: 'ChartSettings',
  setup() {
    const { addEscapeCharacter, scrollToErrorMessage } = useValidateFields()

    return { addEscapeCharacter, scrollToErrorMessage }
  },
  props: ['showChartSettingsSideBar', 'chartConfigurations', 'editChartData'],
  components: {
    colorPicker,
    BIconInfoCircle,
    BarChart: () => import('../charts/BarChart.vue'),
    HorizontalBarChart: () => import('../charts/HorizontalBarChart.vue'),
    LineChart: () => import('../charts/LineChart.vue'),
    PieChart: () => import('./PieChart.vue'),
    DoughnutChart: () => import('./DoughnutChart.vue'),
    CustomizeChartData: () => import('../charts/CustomizeChartData.vue')
  },
  data () {
    return {
      ChatTypeOptions: ChatTypeOptions,
      ColorSchemeOptions: ColorSchemeOptions,
      formSubmitted: false,
      formData: {
        chartType: null,
        chartTitle: null,
        subTitle: null,
        isExistingColor: true,
        colorSchemeLst: ColorSchemeOptions[0].value
      },
      cloneColorSchemeList: ColorSchemeOptions[0].value,
      showColorPicker: -1,
      chartTitleExistError: false,
      filterEditList: [],
      sampleResHeight: 400
    }
  },
  computed: {
    chartHeight () {
      return {
        height: '250px',
        position: 'relative'
      }
    },
    customColors () {
      if (this.ColorSchemeOptions) {
        return this.ColorSchemeOptions.find((color) => color.name === 'Custom')
          .value
      } else {
        return []
      }
    },
    customColorValidations () {
      return this.formData.colorSchemeLst.some(
        (c) => c && c.match(/^#[a-f0-9]{6}$/i) !== null
      )
    },
    getSampleAPIValue () {
      let result = '['
      const seriesFilter = this.chartSampleData.series.filter((s) => s.label)
      seriesFilter.forEach((sd, sIndex) => {
        sd.data.forEach((d, index) => {
          result += `(${this.addEscapeCharacter(sd.label)}, ${
            this.chartSampleData.labels[index]
              ? this.addEscapeCharacter(this.chartSampleData.labels[index])
              : null
          }, ${!isNaN(d) ? (d !== '' ? d : null) : null})`
          result += index + 1 === sd.data.length ? '' : ','
        })
        result += sIndex + 1 === seriesFilter.length ? '' : ','
      })
      result += ']'
      return result
    },
    ...mapState({
      chartSampleData: (state) => state.widgets.chartSampleData
    })
  },
  mounted () {
    this.assignEditChart()
  },
  methods: {
    assignEditChart () {
      this.formData.chartType = this.ChatTypeOptions[0].value
      if (this.editChartData) {
        this.formData = cloneDeep(this.editChartData)
        this.cloneColorSchemeList = cloneDeep(this.formData.colorSchemeLst)
        this.filterEditList = this.chartConfigurations.filter(
          (c) =>
            c.chartTitle.toLowerCase() !==
            this.editChartData.chartTitle.toLowerCase()
        )
      }
    },
    getSampleResHeight () {
      setTimeout(() => {
        const preview = document.getElementById('chart-settings-preview')
        const chartPreview = document.getElementById(
          'chart-configurations-from'
        )
        const height =
          preview && chartPreview
            ? preview.offsetHeight - chartPreview.offsetHeight - 55
            : 0
        this.sampleResHeight = height > 0 ? height : 400
      }, 600)
    },
    onSelectExistingColorScheme () {
      this.cloneColorSchemeList = cloneDeep(this.formData.colorSchemeLst)
    },
    onChangeColorScheme (value) {
      if (!value) {
        this.formData.colorSchemeLst = this.ColorSchemeOptions.find(
          (color) => color.name === 'Custom'
        ).value
      } else {
        this.formData.colorSchemeLst = this.cloneColorSchemeList
      }
    },
    changeColor (color, index) {
      this.formData.colorSchemeLst[index] = color.hex
      this.$set(this.formData.colorSchemeLst, index, color.hex)
    },
    isValidColor (index) {
      if (this.formData.colorSchemeLst[index]) {
        return (
          this.formData.colorSchemeLst[index].match(/^#[a-f0-9]{6}$/i) !== null
        )
      }
      return true
    },
    applyColorToPicker (index) {
      this.showColorPicker = -1
      this.$nextTick(() => {
        this.showColorPicker = index
      })
    },
    onAddUpdateChartForm () {
      this.formSubmitted = true
      if (this.formData.chartTitle) {
        if (this.editChartData) {
          this.chartTitleExistError = this.filterEditList.some(
            (chart) =>
              chart.chartTitle.toLowerCase() ===
              this.formData.chartTitle.toLowerCase()
          )
        } else {
          this.chartTitleExistError = this.chartConfigurations.some(
            (chart) =>
              chart.chartTitle.toLowerCase() ===
              this.formData.chartTitle.toLowerCase()
          )
        }
      } else {
        this.chartTitleExistError = false
      }
      this.scrollToErrorMessage()
      this.getSampleResHeight()
      if (
        this.formData.chartType &&
        !this.chartTitleExistError &&
        this.formData.chartTitle &&
        this.formData.colorSchemeLst.some(
          (c) => c && c.match(/^#[a-f0-9]{6}$/i) !== null
        )
      ) {
        this.$emit('addUpdateChart', this.formData)
      }
    },
    onResetChartForm () {
      this.formSubmitted = false
      this.chartTitleExistError = false
      this.formData = this.editChartData
        ? cloneDeep(this.editChartData)
        : {
          chartType: this.ChatTypeOptions[0].value,
          chartTitle: null,
          subTitle: null,
          isExistingColor: true,
          colorSchemeLst: ColorSchemeOptions[0].value
        }
    },
    sideBarHidden () {
      this.ColorSchemeOptions.forEach((color) => {
        if (color.name === 'Custom') {
          color.value.forEach((v, index) => (color.value[index] = ''))
        }
      })
      this.formSubmitted = false
      this.chartTitleExistError = false
      this.formData = {
        chartType: this.ChatTypeOptions[0].value,
        chartTitle: null,
        subTitle: null,
        isExistingColor: true,
        colorSchemeLst: ColorSchemeOptions[0].value
      }
    }
  },
  watch: {
    formData: {
      deep: true,
      handler () {
        this.getSampleResHeight()
      }
    },
    chartSampleData () {
      this.getSampleResHeight()
    }
  }
}
</script>
<style lang="scss" scoped>
pre {
  white-space: pre-wrap;
}
</style>
